<main>
  <section class="header">
    <app-header [showTitle]="false"></app-header>
    <div class="all-alert">
      <img src="../../assets/icons/Color/Size=S, Icons=Error, Type=Normal.svg" alt="Icono de alerta">
      <div>
        <span>
          Actualmente las oficinas están presentando inconvenientes. En caso de realizar la compra, la emisión de su certificado puede tardar algunas horas.
        </span>
        <span>
          Agradecemos su comprensión.
        </span>
      </div>
    </div>
  </section>
  <section class="main">
    @if (!secondStep()) {
      <h1>Redime aquí tu código</h1>
    }
    <div class="content">
      @if (!secondStep()) {
        <p>Para obtener tu certificado de tradición y libertad con el código adquirido, ingresa los siguientes datos</p>
        <form [formGroup]="form" (ngSubmit)="nextStep()">
          <div>
            <label for="redeemCode">Ingresa tu código redimible *</label>
            <input (change)="onValidateCode()" formControlName="redeemCode" type="text" id="redeemCode">
            @if (ctlForm['redeemCode'].errors && ctlForm['redeemCode'].errors['pattern']) {
              <span class="invalid-message">Caracterer no permitido</span>
            }
          </div>
          <div>
            <label for="office">Oficina *</label>
            <section class="office-list">
              <input (click)="onShowListOffices()" (input)="onInputOffice()" formControlName="office" type="text">
              <div class="office-list__offices" [ngClass]="{'office-list__offices-active': showListCities()}">
                @for (office of listOfficesToShow(); track $index) {
                  <span (click)="onSelectOffice(office)">{{ office.valueOff }} &#45; {{ office.nameOff }}</span>
                }
              </div>
            </section>
          </div>
          <div>
            <label for="number">Numero de matricula *</label>
            <div class="matricula">
              <input class="matricula__officecode" formControlName="officeCode" type="text" maxlength="3">
              <span> &#45; </span>
              <input formControlName="number" type="text" id="number">
            </div>
            @if (ctlForm['number'].errors && ctlForm['number'].errors['pattern']) {
              <span class="invalid-message">Solo se permiten números</span>
            }
          </div>
          <div>
            <app-btn-normal
              [ngClass]="{'btn-disable': !form.valid || ctlForm['officeCode'].value === '000'}"
              [typeBtn]="form.valid ? 'submit' : 'button'"
              [contentBtn]="'Continuar'"
            />
          </div>
        </form>
      } @else {
        <section class="content__elements">
          <div>
            <p class="content__elements-title">Detalle compra del certificado</p>
            <span>Resumen de compra</span>
            <div class="content__elements-table">
              <div>
                <p>Oficina</p>
                <p>{{ selectedOffice().nameOff }}</p>
              </div>
              <div>
                <p>Nro. Matricula</p>
                <p>{{ selectedOffice().valueOff }} - {{ form.value.number }}</p>
              </div>
            </div>
            <div class="content__elements-icon">
              <img (click)="onBackStep()" src="../../../assets/icons/Unicolor/trash-2.svg" alt="">
            </div>
          </div>
          <div>
            <p class="content__elements-title">Código redimible</p>
            <form [formGroup]="codForm" class="content__elements-form">
              <div class="inputs">
                <div class="inputs-input">
                  <div>
                    <label for="pinToValidate">Digita el pin *</label>
                    <input class="btn-disable" formControlName="code" type="text" id="pinToValidate" />
                    @if (ctlFormCode['code'].errors && ctlFormCode['code'].errors['pattern']) {
                      <div class="invalid-message">
                        Caracteres inválidos
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div class="inputs">
                <div class="inputs-input">
                  <div>
                    <label for="email">Correo al que llegará el certificado *</label>
                    <input formControlName="email" type="email" id="email" />
                    @if (ctlFormCode['email'].errors && ctlFormCode['email'].errors['pattern']) {
                      <div class="invalid-message">
                        Formato de correo inválido
                      </div>
                    }
                  </div>
                </div>
                <div class="inputs-input">
                  <div>
                    <label for="confirmEmail">Confirmar correo *</label>
                    <input
                      formControlName="confirmEmail"
                      type="email"
                      id="confirmEmail"
                    />
                    @if (ctlFormCode['confirmEmail'].errors && ctlFormCode['confirmEmail'].errors['pattern']) {
                      <div class="invalid-message">
                        Formato de correo inválido
                      </div>
                    }
                    @if (codForm['errors'] && codForm['errors']['matchingFields'] && ctlFormCode['confirmEmail'].touched) {
                      <div class="invalid-message">
                        Los correos deben coincidir
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div class="inputs">
                <div class="inputs-input">
                  <div>
                    <label for="fullName">Nombre completo <span>(Opcional)</span></label>
                    <input formControlName="fullName" type="text" id="fullName" />
                    @if (ctlFormCode['fullName'].errors && ctlFormCode['fullName'].errors['pattern']) {
                      <div class="invalid-message">
                        Caracteres inválidos
                      </div>
                    }
                  </div>
                </div>
                <div class="inputs-input document">
                  <div>
                    <label for="typeDoc">Tipo documento</label>
                    <select formControlName="typeDoc" id="typeDoc">
                      @for (document of typesDocs(); track document) {
                        <option [value]="document.value">
                          {{ document.nameDT }}
                        </option>
                      }
                    </select>
                  </div>
                  <div>
                    <label for="numDoc">Número documento <span>(Opcional)</span></label>
                    <input formControlName="numDoc" type="text" id="numDoc" maxlength="10"/>
                    @if (ctlFormCode['numDoc'].errors && ctlFormCode['numDoc'].errors['pattern']) {
                      <div class="invalid-message">
                        Solo se permiten números
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div class="inputs">
                <div class="inputs-input">
                  <div>
                    <label for="numPhone">Celular <span>(Opcional)</span></label>
                    <input formControlName="phone" type="text" id="numPhone" maxlength="10"/>
                    @if (ctlFormCode['phone'].errors && ctlFormCode['phone'].errors['pattern']) {
                      <div class="invalid-message">
                        Solo se permiten números
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div class="policies">
                <input formControlName="policies" type="checkbox" id="flexCheckDefault"/>
                <label class="form-check-label" for="flexCheckDefault">
                  Acepto <a href="/terminos-y-condiciones">Terminos y condiciones</a> y
                  <a href="/politicas-privacidad">Políticas de privacidad.</a>.
                </label>
              </div>
              <div class="btn-pay">
                <button (click)="onBackStep()">Regresar</button>
                <app-btn-normal
                  [ngClass]="{'btn-disable': codForm.invalid}"
                  (click)="onCreateTransaction()"
                  [contentBtn]="'Finalizar'"
                />
              </div>
            </form>
          </div>
        </section>
      }
    </div>
  </section>
  @if (showModal()) {
    <section class="modal">
      <section class="modal__info">
        <p class="modal__info-title">
          Codigo redimido exitosamente
        </p>
        @if (!showOutHour() && !showWeekend()) {
          <p>
            <span>
              Recibirás el certificado en el correo <span class="bold">{{ infoTransaction().email }}</span> en los próximos 30 minutos
            </span>
          </p>
        }
        @if (showOutHour() && !showWeekend()) {
          <p>
            <span>
              Tu certificado(s) será generado a partir de las 6:30 am. Recibirás el certificado en el correo indicado
            </span>
          </p>
        }
        @if (showWeekend()) {
          <p>
            <span>
              Tu certificado(s) será generado a partir de las 6:30 am de lunes a sabado
            </span>
            <span>
              Recibirás el certificado en el correo indicado
            </span>
          </p>
        }
        <div class="modal__info-content">
          <div>
            <p>Fecha</p>
            <p>{{ infoTransaction().created_at | date:'medium' }}</p>
          </div>
          <div>
            <p>Medio de pago</p>
            <p>Código redimible</p>
          </div>
          <div>
            <p>Estado</p>
            <p>Aprobado</p>
          </div>
          <div>
            <p>ID de la solicitud</p>
            <p>{{ infoTransaction().id_request }}</p>
          </div>
          <div>
            <p>Correo</p>
            <p>{{ infoTransaction().email }}</p>
          </div>
        </div>
        <div class="modal__info-btns">
          <app-btn-normal
            routerLink="/"
            [contentBtn]="'Finalizar'"
          ></app-btn-normal>
        </div>
      </section>
    </section>
  }
  <app-footer></app-footer>
</main>
